var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-img',{staticStyle:{"margin-top":"15px"},attrs:{"src":"https://i.imgur.com/vZJHTUT.png","max-width":"70px"}}),_c('v-spacer'),_c('v-autocomplete',{staticClass:"black--text",staticStyle:{"width":"15vw"},attrs:{"items":_vm.menuList,"color":"white","dark":"","clearable":""},on:{"change":function($event){return _vm.addToCartSearch()}},model:{value:(_vm.toCart),callback:function ($$v) {_vm.toCart=$$v},expression:"toCart"}}),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"30vw","offset-y":"","origin":"top right","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"color":"red","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.shoppingCart.length))])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-cart ")])],1)],1)]}}])},[_c('v-list',{attrs:{"tile":false,"nav":"","flat":""}},[(_vm.shoppingCart.length !== 0)?_c('div',[_vm._l((_vm.shoppingCart),function(p,i){return [_c('v-row',{key:("row-" + i),staticStyle:{"margin-right":"20px"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"8","sm":"8","md":"8"}},[_c('app-bar-item',{key:("item-" + i),attrs:{"to":"/"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(p.name)}})],1)],1),_c('v-col',{attrs:{"cols":"2","sm":"2","md":"2"}},[_c('span',[_vm._v(_vm._s(p.num))])]),_c('v-col',{attrs:{"cols":"2","sm":"2","md":"2"}},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"secondary","elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.$store.commit('REMOVE_CART', p.name)}}},[_c('v-icon',[_vm._v(" mdi-basket-remove ")])],1)],1)],1),_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"})]})],2):_c('div',{staticStyle:{"padding":"30px"}},[_c('span',[_vm._v("Nu exista niciun produs")])]),_c('v-spacer'),_c('v-list-item',[_c('v-btn',{attrs:{"elevation":"1","color":"blue","dark":""}},[_c('v-icon',[_vm._v(" mdi-cart ")])],1)],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-account ")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_vm._l((_vm.profile),function(p,i){return [(p.divider)?_c('v-divider',{key:("divider-" + i),staticClass:"mb-2 mt-2"}):(p.language)?_c('app-bar-item',{key:("item-" + i),attrs:{"to":"/"}},[_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text",attrs:{"color":"transparent","dark":false,"elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Language Selector ")])]}}],null,true),model:{value:(_vm.dialogLang),callback:function ($$v) {_vm.dialogLang=$$v},expression:"dialogLang"}},[_c('v-card',{staticStyle:{"overflow":"hidden"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Language ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-autocomplete',{attrs:{"color":"green","item-color":"green","items":_vm.languages},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogLang = false}}},[_vm._v(" Confirm ")])],1)],1)],1)],1):_c('app-bar-item',{key:("item-" + i),attrs:{"to":"/"}},[(p.title === 'Change table')?_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"black--text",attrs:{"color":"transparent","dark":false,"elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(p.title)+" ")])]}}],null,true),model:{value:(_vm.dialogTable),callback:function ($$v) {_vm.dialogTable=$$v},expression:"dialogTable"}},[_c('v-card',{staticStyle:{"overflow":"hidden"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Select table ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-autocomplete',{attrs:{"color":"green","item-color":"green","items":_vm.$store.state.tNumbers},model:{value:(_vm.tableNumber),callback:function ($$v) {_vm.tableNumber=$$v},expression:"tableNumber"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogTable = false; this.$store.commit('SET_TABLE', _vm.tableNumber)}}},[_vm._v(" Confirm ")])],1)],1)],1):_c('v-btn',{staticClass:"black--text",attrs:{"color":"transparent","dark":false,"elevation":"0"},on:{"click":function($event){return _vm.logOut()}}},[_vm._v(" "+_vm._s(p.title)+" ")])],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }