<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-img
      src="https://i.imgur.com/vZJHTUT.png"
      max-width="70px"
      style="margin-top: 15px"
    />

    <v-spacer />

    <v-autocomplete
      v-model="toCart"
      class="black--text"
      :items="menuList"
      style="width: 15vw"
      color="white"
      dark
      clearable
      @change="addToCartSearch()"
    />
    <v-menu
      bottom
      left
      min-width="30vw"
      offset-y
      origin="top right"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
          >
            <template v-slot:badge>
              <span>{{ shoppingCart.length }}</span>
            </template>

            <v-icon color="white">
              mdi-cart
            </v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
        flat
      >
        <div
          v-if="shoppingCart.length !== 0"
        >
          <template
            v-for="(p, i) in shoppingCart"
          >
            <v-row
              :key="`row-${i}`"
              justify="center"
              align="center"
              style="margin-right: 20px"
            >
              <v-col
                cols="8"
                sm="8"
                md="8"
              >
                <app-bar-item
                  :key="`item-${i}`"
                  to="/"
                >
                  <v-list-item-title v-text="p.name" />
                </app-bar-item>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <span>{{ p.num }}</span>
              </v-col>
              <v-col
                cols="2"
                sm="2"
                md="2"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  elevation="1"
                  fab
                  small
                  @click="$store.commit('REMOVE_CART', p.name)"
                >
                  <v-icon>
                    mdi-basket-remove
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider
              :key="`divider-${i}`"
              class="mb-2 mt-2"
            />
          </template>
        </div>
        <!--        <div v-if="shoppingCart.length !== 0">-->
        <!--          <v-list-item-content>-->
        <!--            <v-list-item-title v-text="`${n.name}`" />-->

        <!--            <span @click="shoppingCart = n.name">x</span>-->
        <!--          </v-list-item-content>-->
        <!--          <v-divider-->
        <!--            :key="`divider-${i}`"-->
        <!--            class="mb-2 mt-2"-->
        <!--          />-->
        <!--        </div>-->
        <div
          v-else
          style="padding: 30px"
        >
          <span>Nu exista niciun produs</span>
        </div>
        <v-spacer />
        <v-list-item>
          <v-btn
            elevation="1"
            color="blue"
            dark
          >
            <v-icon>
              mdi-cart
            </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="white">
            mdi-account
          </v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        flat
        nav
      >
        <template v-for="(p, i) in profile">
          <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-2 mt-2"
          />

          <app-bar-item
            v-else-if="p.language"
            :key="`item-${i}`"
            to="/"
          >
            <v-dialog
              v-model="dialogLang"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  class="black--text"
                  :dark="false"
                  v-bind="attrs"
                  elevation="0"
                  v-on="on"
                >
                  Language Selector
                </v-btn>
              </template>

              <v-card style="overflow: hidden">
                <v-card-title class="headline">
                  Language
                </v-card-title>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    md="9"
                    sm="9"
                  >
                    <v-autocomplete
                      v-model="lang"
                      color="green"
                      item-color="green"
                      :items="languages"
                    />
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialogLang = false"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </app-bar-item>

          <app-bar-item
            v-else
            :key="`item-${i}`"
            to="/"
          >
            <v-dialog
              v-if="p.title === 'Change table'"
              v-model="dialogTable"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  class="black--text"
                  :dark="false"
                  v-bind="attrs"
                  elevation="0"
                  v-on="on"
                >
                  {{ p.title }}
                </v-btn>
              </template>

              <v-card style="overflow: hidden">
                <v-card-title class="headline">
                  Select table
                </v-card-title>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    md="9"
                    sm="9"
                  >
                    <v-autocomplete
                      v-model="tableNumber"
                      color="green"
                      item-color="green"
                      :items="$store.state.tNumbers"
                    />
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialogTable = false; this.$store.commit('SET_TABLE', tableNumber)"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              v-else
              color="transparent"
              class="black--text"
              :dark="false"
              elevation="0"
              @click="logOut()"
            >
              {{ p.title }}
            </v-btn>
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  // import router from '@/router'
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      toCart: '',
      dialogLang: false,
      dialogTable: false,
      lang: 'Romanian',
      languages: ['English', 'Romanian', 'French'],
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
      profile: [
        { title: 'Change table' },
        { language: true },
        { divider: true },
        { title: 'Leave table' },
      ],
    }),

    computed: {
      ...mapState(['drawer']),
      shoppingCart: {
        get () { return this.$store.getters.get_cart },
        set (val) {
          this.$store.commit('SET_CART', val)
        },
      },
      tableNumber: {
        get () { return this.$store.getters.get_table },
        set (val) {
          this.$store.commit('SET_TABLE', val)
        },
      },
      menuList: {
        get () {
          var result = []
          for (var i = 0; i < this.$store.getters.get_menu.length; i++) {
            result.push(this.$store.getters.get_menu[i].name)
          }
          return result
        },
      },
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      logOut () {
        localStorage.removeItem('tableNumber')
        window.location.reload()
      },

      addToCartSearch () {
        if (this.toCart !== '' && this.toCart !== undefined) {
          this.shoppingCart = this.toCart
          this.toCart = ''
        }
      },
    },
  }
</script>

<style lang="scss">
.v-text-field {
  input {
    color: white;
  }
}
</style>
